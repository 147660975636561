/* eslint-disable */

export default {
  methods: {
    /* ok */
    _tryToLogin(data) {
      return this.makeReq({
        method: "POST",
        url: "paymapi/v1/panel/client/login",
        data
      });
    },
    /* ok */
    _readSettings() {
      return this.makeReq({
        method: "GET",
        url: "paymapi/v1/panel/client/settings/read"
      });
    },
    /* ok */
    _paymentMethodList() {
      return this.makeReq({
        method: "GET",
        url: "paymapi/v1/panel/client/pmethod/list"
      });
    },
    /* ok */
    _transactionList(data) {
      return this.makeReq({
        method: "POST",
        url: "paymapi/v1/panel/client/transaction/list",
        data,
        returnAll: true
      });
    },
    _readTransaction(id) {
      return this.makeReq({
        method: "GET",
        url: `paymapi/v1/panel/client/transaction/read/${id}`
      });
    }
  }
};
